import axios from "axios";

 function customInterceptor(config){
    const jwt = localStorage.getItem("jwt")
    if(jwt)
        config.headers.authorization = `Bearer ${jwt}`
    config.timeout = 60000
    config.maxContentLength = Infinity
    console.log(config)
    return config;
}
export function createAxios(baseURL,headers={}){
    console.log("baseUrl",baseURL)
        const newInstance = axios.create({baseURL,headers:{...headers,}});

        //newInstance.interceptors.request.use(customInterceptor);
        //newInstance.interceptors.response.use((res)=>{ return res; })
        
        return {get: get.bind({axios: newInstance}), post: post.bind({axios: newInstance})}
}

export async function get(url,queryParams, options={}){

    if(!url){
        return Promise.reject({status:400})
    }

    if(queryParams){
        url = createUrl(url, queryParams)
    }
    const apiServer = getAxiosContext(this);

    const promiseCallback = async(resolve, reject) =>{
        try{
            const res = await apiServer.get(url,options)
            return resolve(res)
        }catch(error){
            console.log(error)
            return reject(error.response)
        }
    }
    return new Promise(promiseCallback);
 }

export async function post(url,payload, options={}){
    if(!url){
        return Promise.reject({status:400})
    }
    if(!options.headers){
        options.headers = {}
        options.headers["Content-Type"] = "application/json"
    }
    const apiServer = getAxiosContext(this);

const promiseCallback = async(resolve, reject) =>{
        try{
            const res = await apiServer.get(url,options)
            return resolve(res)
        }catch(error){
            console.log(error)
            return reject(error.response)
        }
    }
    return new Promise(promiseCallback);
 }

 const createUrl = (url, queryOptions) =>{
    return url+"?"+queryString(queryOptions);
 }

 const queryString = (params) =>{
    return Object.keys(params).map((key)=>`${key}=${params[key]}`)
 }

 function getAxiosContext(context){

    return context.axios;
 }