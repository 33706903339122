import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import { fetchMessage } from './service/service';
import ReactPlayer from 'react-player';

function App() {
  useEffect(()=>{
    fetchMessage1()
  },[])
  const [message, setMessage1] = useState("")
  async function fetchMessage1(){

    await fetchMessage().then((res)=>{
      setMessage1(res.data)
    }).catch((error)=>{
      console.log(error)
    })
  }



  return (
    <div className="App">
      <header className="App-header">{message}
          <h3 >Sai Deepika's Stories</h3>
          
          <div style={{padding:20, width:1500}}><ReactPlayer
            className='react-player fixed-bottom'
            url= '/content/videos/pre-wed.mov'
            width='100%'
            height='100%'
            controls = {true}

          /></div>
          
      </header>
    </div>
  );
}

export default App;
